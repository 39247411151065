import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

class ThemeToggle extends Component {
  state = {
    mode: '☀︎',
  };

  render() {
    return (
      <span
        style={{
          cursor: 'pointer',
          userSelect: 'none'
          // fontSize: '20px',
        }}
        className="themeToggler"
        role="img"
        aria-label="light"
        onClick={this.toggleTheme}
      >
        {this.state.mode}
      </span>
    );
  }

  componentDidMount = () => {
    const mode = localStorage.getItem('mode');
    mode === 'light' ? document.getElementById("body").classList.add('light') : undefined;
  };

  toggleTheme = () => {
    const isLightMode = document.getElementById("body").classList.contains('light');
    if (isLightMode) {
      document.getElementById("body").classList.remove('light');
      document.getElementById("body").classList.add('dark');
      this.setState({
        mode: '☀︎',
      });
      localStorage.setItem('mode', 'dark');
    } else {
      document.getElementById("body").classList.remove('dark');
      document.getElementById("body").classList.add('light');
      this.setState({
        mode: '☽',
      });
      localStorage.setItem('mode', 'light');
    }
  };
}

export default ThemeToggle;
