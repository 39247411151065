import React from 'react';
import { Icon } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import SocialMediaBlock from '../SocialMediaBlock/SocialMediaBlock';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <FontAwesomeIcon icon={faHeart} /> 2021 - Fox Gardner
        <a
          href='https://github.com/JLDevOps/gatsby-comic-template'
          target="_blank"
          rel="noopener noreferrer"
          title='Github'
        >
        &nbsp;
        </a>
        <SocialMediaBlock></SocialMediaBlock>
      </footer>
    );
  }
}

export default Footer;
