import React from 'react';
import { BackTop } from 'antd';
import Header from '../Header/Header';
import Tags from '../Tags/Tags';
import Bio from '../Bio/Bio';
import Footer from '../Footer/Footer';
import SocialMediaBlock from '../SocialMediaBlock/SocialMediaBlock';
import LatestPosts from '../LatestPosts/LatestPosts';

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div id="body" class="dark">
        <React.Fragment>
          <Header />
            <div className="main-content">
              <main class="pl-4 pt-0 pr-4">{children}</main>
              <aside class="sides">
                <LatestPosts></LatestPosts>
                {/* <Tags /> */}
              </aside>
              {/* <Bio /> */}
              {/* <SocialMediaBlock></SocialMediaBlock> */}
              <Footer />
            <BackTop />
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default Layout;
